import { Box, Center, createStyles, Paper, SimpleGrid, Text } from "@mantine/core";
import { Section } from "../components/Partials/Section";

const useStyles = createStyles((theme) => ({
    paper: {
        borderRadius: '5px',
        padding: '20px',
    },
    text: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '20px',
        lineHeight: '120%',
        marginBottom: '30px',
        [`@media (max-width: ${theme.breakpoints.md})`]: {
            fontSize: "22px",  
        },
    },
    price: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '40px',
        lineHeight: '40px',
        color: '#FFFFFF',
        [`@media (max-width: ${theme.breakpoints.md})`]: {
            fontSize: "30px",  
        },
    }
}));

//temp data
const rates = [
    {
        id: 1,
        type: 'Бронь на час',
        price: 1000,
    },
    {
        id: 2,
        type: 'Бронь на смену',
        price: 2000,
    },
    {
        id: 3,
        type: 'Бронь на пол смены',
        price: 3000,
    },
    {
        id: 4,
        type: 'Бронь на весь день',
        price: 4000,
    },

];
// background colors for the paper
const COLORS = ['#2AAD6F', '#B316FA', '#166FFA', '#E0701F']

export function Rates(): JSX.Element {
    const {classes} = useStyles();
    return (
        <Box>
            <Section title="Цены" sx={{textAlign: 'center'}}/>
            <Center>
                <SimpleGrid 
                cols={4} 
                spacing='40px'
                breakpoints={[
                    { maxWidth: "xs", cols: 1 },
                    { maxWidth: "sm", cols: 2 },
                    { maxWidth: "md", cols: 3 },
                ]}>
                {
                    rates.map((rate) => (
                        <Paper 
                        withBorder 
                        className={classes.paper}
                        style={{backgroundColor: COLORS[rate.id - 1]}} 
                        >
                            <Text className={classes.text}>{rate?.type}</Text>
                            <Text className={classes.price}>
                            {rate?.price.toLocaleString("ru-RU", {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: rate?.price >= 10000 ? 1 : 0,
                            })}{" "}
                            ₽/час
                            </Text>
                        </Paper>
                    ))
                }
                </SimpleGrid>
            </Center>
        </Box>
    )
}
