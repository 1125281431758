import { Box, Button, createStyles, Paper, Spoiler, Stack, Text } from '@mantine/core';
import { IconMapPin } from '@tabler/icons-react';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWorkspaceById, initWidget } from '../../store/slices/object.slice';
import { yooKassaWidget } from '../../widgets/yookassa';
import { modals } from '@mantine/modals';
import { Payment } from '../Modal/Payment';
import { selectDate, selectDuration, selectTime } from '../../store/slices/search.slice';
import moment from 'moment';

const useStyles = createStyles((theme) => ({
  primeBox: {
    maxWidth: 409,
    marginTop: '2rem',
  },
  paper: {
    transition: 'background-color 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: theme.colors.gray[2],
      cursor: 'pointer',
    },
  },
  image: {
    width: '100%',
    maxHeight: 236,
    background: 'url(image.png)',
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    flex: 'none',
    order: 0,
    flexGrow: 0,
  },
  title: {
    maxWidth: 321,
    fontSize: '25px',
    lineHeight: '120%',
    paddingBottom: '10px',
    paddingTop: '15px',
    [`@media (max-width: ${theme.breakpoints.md})`]: {
      fontSize: '20px',
    },
  },
  city: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    width: '149px',
    height: '21px',
    paddingBottom: '10px',
  },
  description: {
    fontSize: '16px',
    lineHeight: '130%',
    marginBottom: '10px',
  },
  price: {
    fontSize: '20px',
    lineHeight: '120%',
    color: '#FF7400',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '10px 30px',
    flexDirection: 'row',
    width: 'auto',
    height: '44px',
    fontSize: '20px',
    fontWeight: 'normal',
    backgroundColor: '#FF7400',
    borderRadius: '5px',
    ':hover': {
      backgroundColor: '#FAA83B',
      opacity: 0.8,
      border: '1px solid #FAA83B',
      color: 'white',
    },
    ':active': {
      boxShadow: 'inset 0px 7px 10px rgba(72, 33, 0, 0.15)',
    },
  },
  spoiler: {
    color: '#FF7400',
    opacity: 0.8,
  },
}));

const CALLBACK_URL = 'http://localhost:3000';
const NUMBER = '+994517746588';
export function ObjectDetailsCard(): JSX.Element {
  const { workspaceId } = useParams();
  const { selectedWorkspace, payment } = useSelector((state: any) => state.objectSlice);
  const { authData } = useSelector((state: any) => state.authSlice);
  const date = useSelector(selectDate);
  const duration = useSelector(selectDuration);
  const time = useSelector(selectTime);

  const normalizeDatas = () => {
    const normalizedDate = moment(date).format('YYYY-MM-DD');
    const checkIn = moment(`${normalizedDate} ${time}`).format('YYYY-MM-DDTHH:mm');
    const checkOut = '2023-12-12T23:00';
    return { checkIn, checkOut };
  };

  const dispatch = useDispatch();

  const renderYooKassaWidget = (): void => {
    const dates = normalizeDatas();
    const datas = {
      userId: authData?.user?.id,
      number: NUMBER,
      ...dates,
      workspaceId: +selectedWorkspace.id,
      ttlock_lock_id: selectedWorkspace.ttlock_lock_id,
      price: selectedWorkspace.price,
      cityId: selectedWorkspace.cityId,
      cityName: selectedWorkspace.cityName,
      name: selectedWorkspace.name,
    };
    const widgetPayload = { amount: selectedWorkspace.price, metadata: datas };
    dispatch(initWidget(widgetPayload));
  };
  useEffect(() => {
    if (payment.confirmation) {
      yooKassaWidget(payment.confirmation.confirmation_token, CALLBACK_URL);
    }
  }, [payment]);

  useEffect(() => {
    dispatch(getWorkspaceById(workspaceId));
  }, [workspaceId]);

  const { classes } = useStyles();
  return (
    <Box className={classes.primeBox}>
      <Paper key={selectedWorkspace?.id} shadow="lg" p="md" withBorder className={classes.paper}>
        <img src={selectedWorkspace?.images?.[0]} alt={selectedWorkspace?.name} className={classes.image} />
        <Text className={classes.title}>{selectedWorkspace?.name}</Text>
        <Text className={classes.city}>
          <IconMapPin color="#FF7400" />
          {selectedWorkspace?.city}
        </Text>
        <Spoiler
          maxHeight={40}
          showLabel="Показать больше"
          hideLabel="Скрыть"
          classNames={{ control: classes.spoiler }}
        >
          <Text className={classes.description}>{selectedWorkspace?.description}</Text>
        </Spoiler>
        <Box>
          <Stack align="center">
            <Text className={classes.price}>
              {selectedWorkspace?.price.toLocaleString('ru-RU', {
                minimumFractionDigits: 0,
                maximumFractionDigits: selectedWorkspace?.price >= 10000 ? 1 : 0,
              })}
              ₽/час
            </Text>
            <Button
              className={classes.button}
              onClick={() =>
                modals.open({
                  title: <></>,
                  children: <Payment renderYooKassaWidget={renderYooKassaWidget} />,
                })
              }
            >
              Оплатить
            </Button>
          </Stack>
        </Box>
      </Paper>
    </Box>
  );
}
