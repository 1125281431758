import { Box, createStyles, Title } from "@mantine/core";
import { IHeader } from "../../types";
import { IconSparkles } from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
    box: {
        marginTop: "40px",
        marginBottom: "40px",
        [`@media (max-width: ${theme.breakpoints.md})`]: {
            marginTop: "20px",
        },
    },
    sectionTitle: {
        fontWeight: 400,
        fontSize: 40,
        fontStyle: 'normal',
        [`@media (max-width: ${theme.breakpoints.md})`]: {
            fontSize: 30,
        },
    },
    icon: {
        marginRight: 10,
        color: '#FF7400',
        width: 30,
        height: 30,
        [`@media (max-width: ${theme.breakpoints.md})`]: {
            width: 22.5,
            height: 22.5,
        },
    }
}));

export function Section(props: IHeader): JSX.Element {
    const {classes} = useStyles();
    const {title, ...restProps} = props;
    return (
        <Box {...restProps} className={classes.box}>
            <Title className={classes.sectionTitle}>
                <IconSparkles className={classes.icon}/>
                {title}
            </Title>
        </Box>
    )
}