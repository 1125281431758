export function convertPrice(price: number): string {

    return price?.toLocaleString("ru-RU", {
      minimumFractionDigits: 0,
      maximumFractionDigits: price >= 10000 ? 1 : 0,
    });
}
export function convertDate(date: Date) {
    return date?.toLocaleDateString("ru-RU", {
        day: "numeric",  
        month: "numeric",
        year: "numeric",
    });
}