import { createStyles, Checkbox, Box, CheckboxProps, SimpleGrid, Text } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { ICheckbox } from "../../types";

const useStyles = createStyles((theme) => ({
    title: {
        fontWeight: 400,
        fontSixe: '20px',
        lineHight: '120%',
        color: '#C15B16',
        paddingBottom: '10px'
    },
    checkbox: {
        ":checked": {
        backgroundColor: 'white',
        borderColor: 'black',
        },
    },
    boxBorder: {
        paddingBottom: '20px',
    },
}));

export function Checkboxes(props: ICheckbox): JSX.Element {
    const {classes} = useStyles();
    const {title, options, columns, ...restProps} = props;
    const CheckboxIcon: CheckboxProps['icon'] = ({...others}) => (
        <IconCheck {...others} color='#FF7400'/>
    );
    return(
        <Box {...restProps} className={classes.boxBorder}>
            <Text className={classes.title}>{title}</Text>
            <SimpleGrid cols={columns}>
            {options.map((option, index) => (
                options.length > 4 ? (
                    <SimpleGrid key={index}>
                    <Checkbox
                        key={index}
                        label={option}
                        icon={CheckboxIcon}
                        classNames={{input: classes.checkbox}}
                    />
                    </SimpleGrid>
                ) : (
                    <Checkbox
                    key={index}
                    label={option}
                    icon={CheckboxIcon}
                    classNames={{input: classes.checkbox}}
                    />
                )
            ))}
            </SimpleGrid>
        </Box>
    );
}