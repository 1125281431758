import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL || 'http://localhost:8080/';
const instance = axios.create({
  baseURL: 'http://localhost:8080/',
});

console.log(baseURL, 'baseURL');
export const API = {
  register: async (datas: any): Promise<any> => {
    const response = await instance.post('user/auth/register', datas);
    return response;
  },
  login: async (datas: any): Promise<any> => {
    console.log(datas, 'login');
    const response = await instance.post('user/auth/login', datas);
    return response;
  },

  getProfile: async (accessToken: any): Promise<any> => {
    const headers = { 'x-access-token': 'Bearer ' + accessToken };
    const response = await instance.get('user/profile', { headers });
    return response;
  },
  getUserBookings: async (accessToken: any): Promise<any> => {
    const headers = { 'x-access-token': 'Bearer ' + accessToken };
    const response = await instance.get('/dashboard/booking/my-bookings', { headers });
    return response;
  },
  initWidget: async (datas: any): Promise<any> => {
    console.log(datas);
    const payment = await instance.post('/payment/init-widget', datas);
    return payment;
  },
  getObjects: async (params: any): Promise<any> => {
    const objects = await instance.get('/dashboard/search/properties', { params });
    return objects;
  },
  getWorkspaces: async (params: any): Promise<any> => {
    const workspaces = await instance.get('/dashboard/search/workspaces', { params });
    return workspaces;
  },
  getObjectById: async (objectId: number): Promise<any> => {
    const object = await instance.get(`/dashboard/search/property/${objectId}`);
    return object;
  },
  getWorkspaceById: async (workspaceId: number): Promise<any> => {
    const workspace = await instance.get(`/dashboard/search/workspace/${workspaceId}`);
    return workspace;
  },
  getUobjects: async (): Promise<any> => {
    const uObjects = await instance.get('/objects/uobjects');
    return uObjects;
  },
  getOneUIbject: async (objectId: number): Promise<any> => {
    const uObject = await instance.get(`/objects/uobject/${objectId}`);
    return uObject;
  },
  getRoomTypes: async (datas: any): Promise<any> => {
    const roomCategories = await instance.post('objects/room-categories');
    return roomCategories;
  },
  getTarifs: async (datas: any): Promise<any> => {
    const tarifs = await instance.post('objects/tarifs', datas);
    return tarifs;
  },
};
