export const yooKassaWidget = (confirmationToken: string, callBackUrl: string = 'https://yookassa.ru/') => {
  const checkout = new (window as any).YooMoneyCheckoutWidget({
    confirmation_token: confirmationToken, //Токен, который вы получили после создания платежа
    // return_url: callBackUrl, //Ссылка на страницу завершения оплаты
    error_callback: function (error: any) {
      console.log(error);
    },
  });
  checkout.on('complete', () => {
    console.log('complted');
    checkout.destroy();
  });

  // checkout.on('success', (d: any) => {
  //   console.log('success', d);
  //   // axios.post();
  //   // checkout.destroy();
  // });

  // checkout.on('fail', (d: any) => {
  //   console.log('fail', d);
  //   //Код, который нужно выполнить после неудачной оплаты.

  //   //Удаление инициализированного виджета
  // });
  checkout.render('payment-form');
};
