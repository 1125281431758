import { Box, createStyles, NumberInput, SimpleGrid, Text } from "@mantine/core";
import { IPrices } from "../../types";

const useStyles = createStyles((theme) => ({
    title: {
        fontWeight: 400,
        fontSixe: '20px',
        lineHight: '120%',
        color: '#C15B16',
        paddingBottom: '10px'
    },
    numberInputBorder: {
        borderColor: '#FAA83B',
        "&:focus": {
          borderColor: '#FAA83B'
        } 
    },
    boxBorder: {
        paddingBottom: '20px',
    },
}));

export function Prices(props: IPrices): JSX.Element {
    const {classes} = useStyles();
    const {title, labels, ...restProps} = props;
    return(
        <Box {...restProps} className={classes.boxBorder}>
            <Text className={classes.title}>{title}</Text>
            <SimpleGrid cols={2}>
                {
                    labels.map((label, index) => (
                        <NumberInput
                        key={index}
                        placeholder={label}
                        hideControls
                        classNames={{input: classes.numberInputBorder}}
                        />
                    ))
                }
            </SimpleGrid>
        </Box>
    )
}