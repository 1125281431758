import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import './App.css';
import { Header } from './components/Layouts/Header';

import { SearchPanel } from './components/SearchPanel/SearchPanel';
import Router from './routes';
import { setLoginState } from './store/slices/auth.slice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

export default function App() {
  const dispatch = useDispatch();
  const authData = localStorage.getItem('authData');
  const { isLogined } = useSelector((state: any) => state.authSlice);
  useEffect(() => {
    if (authData) {
      dispatch(setLoginState(authData));
    }
  }, [authData]);

  return (
    <>
      <ModalsProvider>
        <MantineProvider>
          <Header isLogined={isLogined} />
          <div className="container">
            <SearchPanel />
            <Router />
          </div>
        </MantineProvider>
      </ModalsProvider>
    </>
  );
}
