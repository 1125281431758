import { ActionIcon, Box, Button, createStyles, Flex, HoverCard, Image, NumberInput, Paper, SimpleGrid, Text, TextInput } from "@mantine/core";
import { IconEdit, IconPlus } from "@tabler/icons-react";
import { useDispatch, useSelector } from "react-redux";
import { Section } from "../components/Partials/Section";
import { getProfile } from "../store/slices/auth.slice";

import { useEffect } from 'react';
import { Link } from "react-router-dom";
const useStyles = createStyles((theme) => ({
    icon: {
        color: '#999999',
        width: '20px',
        height: '20px',
        [`@media (max-width: ${theme.breakpoints.md})`]: {
            width: '24px',
            height: '24px',
        },
    },
    boxInput: {
        maxWidth: '900px',
        marginBottom: '40px',
    },
    boxButtons: {
        maxWidth: '420px',
        margin: '0 auto',
    },
    boxImage: {
        [`@media (max-width: ${theme.breakpoints.md})`]: {
            marginBottom: '20px',
        },
        [`@media (max-width: ${theme.breakpoints.sm})`]: {
            marginBottom: '10px',
        },
        [`@media (max-width: ${theme.breakpoints.xs})`]: {
            justifyItems: 'center',
        }
    },
    buttonOutline: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px 25px",
        gap: "10px",
        width: "auto",
        height: "44px",
        border: "1px solid #FF7400",
        borderRadius: "5px",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "20px",
        lineHeight: "120%",
        color: "#232528",
        ":hover": {
            backgroundColor: "#FAA83B",
            opacity: 0.8,
            border: "1px solid #FAA83B",
            color: 'white'
        },
        ":active": {
            boxShadow: 'inset 0px 7px 10px rgba(72, 33, 0, 0.15)',
        },
        [`@media (max-width: ${theme.breakpoints.xs})`]: {
            width: '100%',
        }
    },
    buttonFilled: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px 25px",
        gap: "10px",
        width: "auto",
        height: "44px",
        backgroundColor: "#FF7400",
        border: "1px solid #FF7400",
        borderRadius: "5px",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "20px",
        lineHeight: "120%",
        color: "#F6F6F7",
        ":hover": {
            backgroundColor: "#FAA83B",
            opacity: 0.8,
            border: "1px solid #FAA83B",
        },
        ":active": {
            boxShadow: 'inset 0px 7px 10px rgba(72, 33, 0, 0.15)',
        },
        [`@media (max-width: ${theme.breakpoints.xs})`]: {
            width: '100%',
        }
    },
    popoverText: {
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "12px",
        color: "#999999",
    },
    inputBorder: {
        fontWeight: 400,
        "&:focus": {
          borderColor: '#FAA83B'
        },
    },
    textPrime: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '20px',
        lineHeight: '120%',
        color: '#232528',
        marginBottom: '10px',
        [`@media (max-width: ${theme.breakpoints.md})`]: {
            fontSize: '22px',
        },
        [`@media (max-width: ${theme.breakpoints.xs})`]: {
            textAlign: 'center',
        },

    },
    textSecond: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '120%',
        color: '#232528',
        [`@media (max-width: ${theme.breakpoints.md})`]: {
            fontSize: '12px',
            marginBottom: '40px',

        },
        [`@media (max-width: ${theme.breakpoints.xs})`]: {
            textAlign: 'center',
            marginBottom: '20px',

        },
    },
    ImagePaper: {
        borderRadius: '5px',
        backgroundColor: '#F3E6C8',
        maxWidth: '252px',
        height: '252px',
        padding: '60px',
    },
    boxSpace: {
        marginTop: '130px',
        marginBottom: '60px',
        [`@media (max-width: ${theme.breakpoints.xs})`]: {
            marginTop: '20px',
            marginBottom: '20px',
        },
    }
}));

export function Profile(): JSX.Element {
    const dispatch = useDispatch()
    const parsedJson = JSON.parse(localStorage.getItem('authData') as any)
    const {accessToken} = parsedJson?.data||{}
    const {userData} = useSelector((state:any)=>state.authSlice)||{}
    useEffect(()=>{
        dispatch(getProfile(accessToken))
    },[])
    return(
        <Box>
            <Section title="Мой профиль"/>
            <UserImage/>
            <PersonalInfo userData={userData}/>
            <Buttons/>
        </Box>
    )
}

function PersonalInfo(props:any): JSX.Element {
    const {userData} = props||{}
    const {classes} = useStyles();
    return(
        <Box className={classes.boxInput}>
            <SimpleGrid 
            cols={2} 
            breakpoints={[
                { maxWidth: "xs", cols: 1 },
                { maxWidth: "sm", cols: 2 },
                { maxWidth: "md", cols: 3 },
            ]}>
                <TextInput 
                label="Имя" 
                // placeholder={userData?.name}
                value={userData?.name}
                rightSection={<IconEdit className={classes.icon}/>}
                classNames={{input: classes.inputBorder}}
                size='lg'
                />
                <TextInput 
                label="E-mail"
                value={userData?.email}
                // placeholder={userData?.email}
                rightSection={<IconEdit className={classes.icon}/>}
                classNames={{input: classes.inputBorder}}
                size='lg'
                />
                <TextInput 
                label="Фамилия" 
                // placeholder={userData?.surname}
                value={userData?.surname}
                rightSection={<IconEdit className={classes.icon}/>}
                classNames={{input: classes.inputBorder}}
                size='lg'
                />
                <HoverCard  
                position='right-end'
                withArrow
                >
                    <HoverCard.Target>
                        <NumberInput 
                        label="Телефон" 
                        value={userData?.phone}
                        placeholder={userData?.phone}
                        hideControls
                        classNames={{input: classes.inputBorder}}
                        size='lg'
                        />
                    </HoverCard.Target>
                    <HoverCard.Dropdown>
                        <Text className={classes.popoverText}>
                            <span>
                                Чтобы сменить номер телефона<br/> 
                                надо <span style={{color: '#5CA3FF'}}>связаться</span> с администратором
                            </span>
                        </Text>
                    </HoverCard.Dropdown>
                </HoverCard>
            </SimpleGrid>
        </Box>
    )
}
function Buttons(): JSX.Element {
    const {classes} = useStyles();
    return(
        <Box className={classes.boxButtons}>
            <Flex 
            justify='space-between'
            align='center'
            direction='row'
            wrap='wrap'
            gap={30}>
                <Button className={classes.buttonFilled}>Сохранить изменения</Button>
                <Button className={classes.buttonOutline} variant='outline'>Омена</Button>
                <Button className={classes.buttonOutline} variant='outline'>Выйти</Button>
            </Flex>
        </Box>
    )
}
function UserImage(): JSX.Element {
    const {classes} = useStyles();
    return(
        <Box>
            <SimpleGrid 
            cols={4} 
            className={classes.boxImage}
            breakpoints={[
                { maxWidth: "xs", cols: 1 },
                { maxWidth: "sm", cols: 2 },
                { maxWidth: "md", cols: 3 },
            ]}>
                <Paper className={classes.ImagePaper}>
                    <Image src='/icons/Profile.svg' alt='profile' width='100%' height='100%'/>
                </Paper>

                <Box>
                    <Link to={'/user/bookings'}>Мои Бронирования</Link>
                    <Text className={classes.textPrime}>Измените информацию</Text>
                    <Text className={classes.textSecond}>
                        {/* Дата регистрации: <span style={{color: '#FF7400'}}>{userData.date}</span> */}
                    </Text>
                    <Box className={classes.boxSpace}>
                        <Button 
                        className={classes.buttonOutline} 
                        variant='outline' 
                        leftIcon={<IconPlus/>}
                        sx={{marginBottom: '10px'}}
                        >
                            Загрузить изображение
                        </Button>
                        <Text className={classes.textSecond}>Максимальный размер 200 Кб.</Text>
                    </Box>
                </Box>
            </SimpleGrid>
        </Box>
    )
}