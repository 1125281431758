import {
    Box,
    Button,
    createStyles,
    Flex,
    Paper,
    SimpleGrid,
    Spoiler,
    Text,
} from "@mantine/core";
import { Section } from "../components/Partials/Section";
import { SearchPanel } from "../components/SearchPanel/SearchPanel";
import { Carousel } from "@mantine/carousel";
import { IconMapPin } from "@tabler/icons-react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getWorkspaces } from "../store/slices/object.slice";
import { convertPrice } from "../components/utils";

import { Rates } from "./Rates";
import { Link } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  primePaper: {
    backgroundColor: "#FAA83B",
    borderRadius: "5px",
    padding: "25px",
    marginTop: "37px",
    [`@media (max-width: ${theme.breakpoints.md})`]: {
      marginTop: "40px",
      padding: "16px",
    },
  },
  card: {
    width: "100%",
    padding: "20px",
    backgroundColor: "#fff",
    borderRadius: "5px",
    transition: "background-color 0.3s ease-in-out",
    "&:hover": {
      backgroundColor: theme.colors.gray[2],
      cursor: "pointer",
    },
    [`@media (max-width: ${theme.breakpoints.md})`]: {
      padding: "10px",
    },
  },
  title: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "40px",
    lineHeight: "40px",
    color: "#232528",
    marginBottom: "10px",
    [`@media (max-width: ${theme.breakpoints.md})`]: {
      fontSize: "22px",
      lineHeight: "120%",
      marginBottom: "5px",
    },
  },
  city: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "120%",
    color: "#232528",
    display: "flex",
    gap: "8px",
    alignItems: "center",
    textAlign: "right",
    marginBottom: "20px",
    [`@media (max-width: ${theme.breakpoints.md})`]: {
      fontSize: "12px",
      lineHeight: "12px",
      marginBottom: "5px",
    },
  },
  description: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "130%",
    color: "#232528",
    [`@media (max-width: ${theme.breakpoints.md})`]: {
      fontSize: "18px",
      lineHeight: "130%",
    },
  },
  price: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "120%",
    color: "#232528",
    marginBottom: "15px",
    marginTop: "20px",
    [`@media (max-width: ${theme.breakpoints.md})`]: {
      fontSize: "18px",
      marginTop: "10px",
      lineHeight: "130%",
      color: "#FF7400",
    },
  },
  button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: "10px 30px",
    flexDirection: "row",
    width: "auto",
    height: "44px",
    fontSize: "20px",
    fontWeight: "normal",
    backgroundColor: "#FF7400",
    borderRadius: "5px",
    ":hover": {
      backgroundColor: "#FAA83B",
      opacity: 0.8,
      border: "1px solid #FAA83B",
      color: "white",
    },
    ":active": {
      boxShadow: "inset 0px 7px 10px rgba(72, 33, 0, 0.15)",
    },
  },
  spoiler: {
    color: "#FF7400",
    opacity: 0.8,
  },
  image: {
    width: "100%",
    height: '377px'
  }
}));


export function Details(): JSX.Element {
  const { classes } = useStyles();
  return (
    <Box>
      <Paper className={classes.primePaper}>
        <DetailsCard />
      </Paper>
      <Section title="Расписание" sx={{ textAlign: "center" }} />
      <SearchPanel />
    </Box>
  );
}
const city_id = 1;

function DetailsCard({ object }: any): JSX.Element {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { workspaces } = useSelector((state: any) => state.objectSlice);
  useEffect(() => {
    dispatch(getWorkspaces({ city_id }));
  }, [dispatch]);
  
  return (
    <Box>
      <Carousel
      slideGap='md'
      withIndicators>

        {workspaces?.rows?.map((object: any, index: number) => (
          <Carousel.Slide>
            <Paper
              withBorder
              shadow="sm"
              className={classes.card}
              key={object?.id}
            >
              <SimpleGrid
                cols={2}
                breakpoints={[
                  { maxWidth: "sm", cols: 1, verticalSpacing: "xs" },
                  { maxWidth: "xs", cols: 1, verticalSpacing: "xs" },
                ]}
              >
                <img src={object?.images?.[0]} alt={object?.name} className={classes.image} />

                <Box>
                  <Text className={classes.title}>
                    {object?.name}
                  </Text>
                  <Text className={classes.city}>
                    <IconMapPin color="#FF7400" />
                    {object?.metro}
                  </Text>
                  <Spoiler
                    maxHeight={20}
                    showLabel="Показать больше"
                    hideLabel="Скрыть"
                    classNames={{ control: classes.spoiler }}
                  >
                    <Text className={classes.description}>
                      {object?.description}
                    </Text>
                  </Spoiler>
                  <Flex
                    justify="space-between"
                    align="center"
                    wrap="wrap"
                    direction="column"
                  >
                    <Text className={classes.price}>
                      {convertPrice(object?.price)}{" "}₽/час
                    </Text>

                    <Link to ='rates'>
                    <Button onClick={(e) => {
                       e.stopPropagation();
                   }} className={classes.button}>Забронировать</Button>
                    </Link>
                  </Flex>
                </Box>
              </SimpleGrid>
            </Paper>
          </Carousel.Slide>
        ))}
      </Carousel>
    </Box>
  );
}
