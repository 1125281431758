import { Box, createStyles, Space, Stack, TextInput } from '@mantine/core';
import { setUserDatas } from '../../store/slices/object.slice';
import { useDispatch, useSelector } from 'react-redux';
import { isNotEmpty, useForm } from '@mantine/form';
import CustomPhoneInput from '../CustomPhone';

const useStyles = createStyles((theme) => ({
  numberInputBorder: {
    '&:focus': {
      borderColor: '#FAA83B',
    },
  },
  label: {
    fontWeight: 400,
  },
}));

export function UserInfo(): JSX.Element {
  const { userDatas } = useSelector((state: any) => state.objectSlice) || {};
  const dispatch = useDispatch();
  const { classes } = useStyles();
  const form = useForm({
    initialValues: {
      name: userDatas.name,
      surname: userDatas.surname,
      phone: userDatas.phone,
      email: userDatas.email,
    },
    validate: {
      name: isNotEmpty('Укажите имя'),
      surname: isNotEmpty('Укажите фамилию'),
      phone: isNotEmpty('Укажите номер телефона'),
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Неверный адрес электронной почты'),
    },
  });
  const handleSubmit = (values: any) => {
    const { name, surname, phone, email } = values;
    dispatch(setUserDatas({ name, surname, phone, email }));
  };
  return (
    <Box>
      <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
        <Stack spacing="xs">
          <TextInput
            classNames={{
              input: classes.numberInputBorder,
              label: classes.label,
            }}
            name="name"
            label="Имя"
            withAsterisk
            {...form.getInputProps('name')}
          />
          <TextInput
            classNames={{
              input: classes.numberInputBorder,
              label: classes.label,
            }}
            name="surname"
            label="Фамилия"
            withAsterisk
            {...form.getInputProps('surname')}
          />
          <TextInput
            type="email"
            classNames={{
              input: classes.numberInputBorder,
              label: classes.label,
            }}
            name="email"
            withAsterisk
            label="Email"
            {...form.getInputProps('email')}
          />
          <CustomPhoneInput {...form.getInputProps('phone')} />
        </Stack>
        <Space h="md" />
      </form>
    </Box>
  );
}
