import { Box, createStyles, Paper, Select } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import { useDispatch, useSelector } from "react-redux";
import { selectTime, setTime } from "../../store/slices/search.slice";

const useStyles = createStyles((theme) => ({
  paper: {
    padding: "5px 6.5px",
    [`@media (max-width: ${theme.breakpoints.md})`]: {
      borderRadius: "5px",
    },
  },
  selectInput: {
    "&[data-selected=true]": {
      backgroundColor: "#FAA83B",
      "&:hover": {
        backgroundColor: "#FF7400",
      },
    },
  },
}));

//temp data
const data = [
  { value: "8:00", label: "8:00" },
  { value: "8:30", label: "8:30" },
  { value: "9:00", label: "9:00" },
  { value: "9:30", label: "9:30" },
  { value: "10:00", label: "10:00" },
  { value: "10:30", label: "10:30" },
  { value: "11:00", label: "11:00" },
];

export function TimeSelecter(): JSX.Element {
  const { classes } = useStyles();
  const time = useSelector(selectTime);
  const dispatch = useDispatch();
  return (
    <Box>
      <Paper className={classes.paper} withBorder radius="0">
        <Select
          placeholder="Выберите время"
          data={data}
          variant="unstyled"
          rightSection={<IconChevronDown size={16} />}
          classNames={{ item: classes.selectInput }}
          onChange={(value) => {
            if (value) {
              dispatch(setTime(value));
            }
          }}
          value={time}
        />
      </Paper>
    </Box>
  );
}
