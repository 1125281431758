export const select_time = [
    { value: '8:00', label: '8:00' },
    { value: '8:30', label: '8:30' },
    { value: '9:00', label: '9:00' },
    { value: '9:30', label: '9:30' },
    { value: '10:00', label: '10:00' },
    { value: '10:30', label: '10:30' },
    { value: '11:00', label: '11:00' },
  ];
export const select_duration = [
    { value: "Смена (с 10:00 до 22:00)", label: "Смена (с 10:00 до 22:00)" },
    { value: "Полсмены (с 10 до 16)", label: "Полсмены (с 10 до 16)" },
    { value: "Полсмены (с 16 до 22)", label: "Полсмены (с 16 до 22)" },
    { value: "1 час", label: "1 час" },
    { value: "1 час 30 мин (до 9:30)", label: "1 час 30 мин (до 9:30)" },
    { value: "2 часа", label: "2 часа" },
    { value: "2 часа 30 мин", label: "2 часа 30 мин" },
  ];