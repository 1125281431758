import { Box, createStyles } from '@mantine/core';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import { useDispatch, useSelector } from 'react-redux';
import { getObjects, getWorkspaces } from '../../store/slices/object.slice';
import { useState, useEffect } from 'react';
const useStyles = createStyles((theme) => ({
  container: {
    height: '600px',
    width: '841px',
    marginTop: '2em',
    marginLeft: '1em',
    [`@media (max-width: ${theme.breakpoints.md})`]: {
      marginLeft: '0',
      width: '600px',
      height: '700px',
    },
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      width: '500px',
    },
    [`@media (max-width: ${theme.breakpoints.xs})`]: {
      width: '328px',
    },
  },
}));

const apikey = process.env.REACT_APP_MAP_API_KEY;
export const YandexMap = ({ placemarks }: any) => {
  const [selectedObjectId, setSelectedObjectId] = useState(null);
  const dispatch = useDispatch();
  const { classes } = useStyles();
  useEffect(() => {
    dispatch(getWorkspaces({ organization_id: 1, property_id: selectedObjectId }));
  }, [selectedObjectId]);
  return (
    <Box className={`${classes.container} map-container`}>
      <div>
        <YMaps query={{ apikey, load: 'package.full' }}>
          <Map
            defaultState={{ center: [55.751574, 37.573856], zoom: 10, controls: ['typeSelector'] }}
            width="100%"
            height="60em"
          >
            {placemarks?.map((placemark: any) => {
              return (
                <Placemark
                  key={placemark?.id}
                  onClick={() => {
                    setSelectedObjectId(placemark?.id);
                  }}
                  options={{
                    iconLayout: 'default#image',
                    iconImageHref: '/icons/marker.svg',
                    iconImageSize: [50, 50],
                    iconImageOffset: [-15, -15],
                    preset: 'islands#circleIcon',
                  }}
                  properties={{
                    balloonContent: `<div  className="balloon-content">
                    <p>${placemark?.name}</p>
                  </div>`,
                  }}
                  modules={['geoObject.addon.balloon']}
                  geometry={placemark?.coordinates}
                />
              );
            })}
          </Map>
        </YMaps>
      </div>
    </Box>
  );
};
