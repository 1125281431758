import { Box, createStyles, Paper, Select } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import { useDispatch, useSelector } from "react-redux";
import { selectDuration, setDuration } from "../../store/slices/search.slice";

const useStyles = createStyles((theme) => ({
  paperPrime: {
    padding: "5px 6px",
    borderRadius: "0px 5px 5px 0px",
    [`@media (max-width: ${theme.breakpoints.md})`]: {
      borderRadius: "5px",
    },
  },
  selectInput: {
    "&[data-selected=true]": {
      backgroundColor: "#FAA83B",
      "&:hover": {
        backgroundColor: "#FF7400",
      },
    },
  },
}));

//temp data
const data = [
  { value: "Смена (с 10:00 до 22:00)", label: "Смена (с 10:00 до 22:00)" },
  { value: "Полсмены (с 10 до 16)", label: "Полсмены (с 10 до 16)" },
  { value: "Полсмены (с 16 до 22)", label: "Полсмены (с 16 до 22)" },
  { value: "1 час", label: "1 час" },
  { value: "1 час 30 мин (до 9:30)", label: "1 час 30 мин (до 9:30)" },
  { value: "2 часа", label: "2 часа" },
  { value: "2 часа 30 мин", label: "2 часа 30 мин" },
];

export function DurationSelecter(): JSX.Element {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const duration = useSelector(selectDuration);

  return (
    <Box>
      <Paper className={classes.paperPrime} withBorder radius="0">
        <Select
          placeholder="Выберите длительность"
          data={data}
          variant="unstyled"
          rightSection={<IconChevronDown size={16} />}
          classNames={{ item: classes.selectInput }}
          onChange={(value) => {
            if (value) {
              dispatch(setDuration(value));
            }
          }}
          value={duration}
        />
      </Paper>
    </Box>
  );
}
