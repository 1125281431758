import { Box, Button, Center, createStyles, Stack, Text } from '@mantine/core';
import { UserInfo } from '../Inputs/UserInfo';

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: '25px',
    lineHeight: '120%',
    fontWeight: 400,
    color: '#232528',
    fontStyle: 'normal',
    marginBottom: '40px',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 30px',
    gap: '10px',
    width: 'auto',
    height: '41px',
    border: '1px solid #FF7400',
    borderRadius: '5px',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '130%',
    color: '#FF7400',
    marginTop: '35px',
    marginBottom: '20px',
    ':hover': {
      backgroundColor: '#FAA83B',
      opacity: 0.8,
      border: '1px solid #FAA83B',
      color: 'white',
    },
    ':active': {
      boxShadow: 'inset 0px 7px 10px rgba(72, 33, 0, 0.15)',
    },
  },
}));

export function Payment({ renderYooKassaWidget }: any): JSX.Element {
  const { classes } = useStyles();
  return (
    <Box>
      <Text className={classes.title}>Введите свои данные для совершения платежа</Text>
      <UserInfo />
      <Center>
        <Stack>
          <Box id="payment-form"></Box>
          <Button onClick={renderYooKassaWidget} type="submit" variant="outline" className={classes.button}>
            Cделать платеж
          </Button>
        </Stack>
      </Center>
    </Box>
  );
}
