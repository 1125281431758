import { createStyles, Box, Text, SimpleGrid, Grid } from "@mantine/core";
import { Section } from "../components/Partials/Section";
import { IconMapPin } from "@tabler/icons-react";
import { EnterenceDate } from "../components/SearchPanel/EnterenceDate";
import { DurationSelecter } from "../components/SearchPanel/Duration";
import { TimeSelecter } from "../components/SearchPanel/TimeSelecter";
import { ObjectDetails } from "../components/Object/ObjectsDetails";
import { useSelector } from "react-redux";
import {
  selectDate,
  selectDuration,
  selectTime,
} from "../store/slices/search.slice";
import { convertDate, convertPrice } from "../components/utils";
import { ObjectDetailsCard } from "../components/Object/ObjectDetailsCard";

const useStyles = createStyles((theme) => ({
  pin: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "8px",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "120%",
    color: "#232528",
    textAlign: "right",
    marginBottom: "40px",
    [`@media (max-width: ${theme.breakpoints.md})`]: {
      marginBottom: "30px",
      fontSize: "23px",
    },
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      marginBottom: "20px",
      fontSize: "20px",
    },
  },
  price: {
    fontStyle: "normal",
    textAlignLast: "center",
    fontWeight: 400,
    fontSize: "40px",
    lineHeight: "40px",
    color: "#FF7400",
    marginTop: "82px",
    [`@media (max-width: ${theme.breakpoints.md})`]: {
      color: "#232528",
      marginTop: "40px",
    },
  },
  objectCard: {
    width: "409px",
    height: "auto",
    [`@media (max-width: ${theme.breakpoints.md})`]: {
      width: "328px",
    },
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      width: "247px",
    },
  },
  panelBox: {
    marginTop: "20px",
    [`@media (max-width: ${theme.breakpoints.md})`]: {
      marginTop: "40px",
    },
  },
  panelVal: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "120%",
    color: "#232528",
  },
  coloredText: {
    color: "#FF7400",
  },
}));

// temp data
const objectDetails = {
  price: 30000,
  city: "Москва",
};

export function Cheackouts(): JSX.Element {
  const { classes } = useStyles();
  return (
    <Box>
      <Grid sx={{justifyContent: 'center'}}>
        <Grid.Col span="auto" md={"auto"}>
          <Section title="Бронирование" />
          <Text className={classes.pin}>
            <IconMapPin color="#FF7400" />
            {objectDetails.city}
          </Text>
          <PanelValues />
          <Panel />
        </Grid.Col>

        <Grid.Col span="content" sx={{ alignSelf: "center"}}>
          <Box className={classes.objectCard}>
            <ObjectDetailsCard />
          </Box>
        </Grid.Col>
      </Grid>
    </Box>
  );
}

function Panel(): JSX.Element {
  const { classes } = useStyles();
  const converted = convertPrice(objectDetails?.price);
  return (
    <Box className={classes.panelBox}>
      <SimpleGrid
        cols={3}
        spacing={0}
        breakpoints={[
          { maxWidth: "lg", cols: 2, spacing: "xs", verticalSpacing: "xs" },
          { maxWidth: "sm", cols: 1, spacing: "xs", verticalSpacing: "xs"}
        ]}
      >
        <EnterenceDate />
        <TimeSelecter />
        <DurationSelecter />
      </SimpleGrid>
      <Text className={classes.price}>
        {converted}{" "}₽
      </Text>
    </Box>
  );
}

function PanelValues(): JSX.Element {
  const { classes } = useStyles();
  const date = useSelector(selectDate);
  const time = useSelector(selectTime);
  const duration = useSelector(selectDuration);
  const converted = convertDate(date);
  const handleInputValues = (e:any)=>{
    console.log(e)
  }
  return (
    <Box>
      <SimpleGrid
        cols={3}
        spacing={5}
        breakpoints={[
          { maxWidth: "sm", cols: 1, spacing: "xs", verticalSpacing: "md" },
          { maxWidth: "lg", cols: 2, spacing: "xs", verticalSpacing: "md" },
        ]}
      >
        <Text   className={classes.panelVal}>
          Дата заезда : <span className={classes.coloredText}>{converted}</span>
        </Text>
        <Text    className={classes.panelVal}>
          Время заезда: <span className={classes.coloredText}>{time}</span>
        </Text>
        <Text    className={classes.panelVal}>
          Продолжительность:{" "}
          <span className={classes.coloredText}>{duration}</span>
        </Text>
      </SimpleGrid>
    </Box>
  );
}
