import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API } from '../../api';
import { stat } from 'fs';

export const initWidget: any = createAsyncThunk(
  'object/initWidget',
  async (datas, { rejectWithValue }): Promise<any> => {
    try {
      const response = await API.initWidget(datas);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response);
    }
  }
);
export const getObjects: any = createAsyncThunk(
  'object/getObjects',
  async (datas, { rejectWithValue }): Promise<any> => {
    try {
      const response = await API.getObjects(datas);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response);
    }
  }
);

export const getWorkspaces: any = createAsyncThunk(
  'object/getWorkspaces',
  async (datas, { rejectWithValue }): Promise<any> => {
    try {
      const response = await API.getWorkspaces(datas);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response);
    }
  }
);

export const getWorkspaceById: any = createAsyncThunk(
  'object/getWorkspaceById',
  async (workspaceId: number, { rejectWithValue }): Promise<any> => {
    try {
      const response = await API.getWorkspaceById(workspaceId);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response);
    }
  }
);

export const getObjectById: any = createAsyncThunk(
  'object/getObjectById',
  async (objectId: number, { rejectWithValue }): Promise<any> => {
    try {
      const response = await API.getObjectById(objectId);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response);
    }
  }
);

const ObjectSlice = createSlice({
  name: 'object',
  initialState: {
    payment: {},
    objects: [],
    workspaces: [],
    selectedObject: null,
    selectedWorkspace: null,

    userDatas: {
      name: '',
      surname: '',
      phone: '',
      email: '',
    },
  },
  reducers: {
    setUserDatas: (state, { payload }) => {
      const { name, surname, phone, email } = payload;
      state.userDatas = { name, surname, phone, email };
    },
  },
  extraReducers: {
    [getObjectById.fulfilled.type]: (state, { payload }) => {
      state.selectedObject = payload.data;
    },
    [getObjects.pending.type]: (state, action) => {},
    [getObjects.rejected.type]: (state, action) => {},
    [getObjects.fulfilled.type]: (state, { payload }) => {
      state.objects = payload.data;
    },

    [getWorkspaces.pending.type]: (state, action) => {},
    [getWorkspaces.rejected.type]: (state, action) => {},
    [getWorkspaces.fulfilled.type]: (state, { payload }) => {
      state.workspaces = payload.data;
    },

    [getWorkspaceById.pending.type]: (state, action) => {},
    [getWorkspaceById.rejected.type]: (state, action) => {},
    [getWorkspaceById.fulfilled.type]: (state, { payload }) => {
      state.selectedWorkspace = payload.data;
    },

    [initWidget.pending.type]: (state, action) => {},
    [initWidget.rejected.type]: (state, action) => {},
    [initWidget.fulfilled.type]: (state, { payload }) => {
      state.payment = payload.data;
    },
  },
});

export const userDatas = (state: any) => state.object.userDatas;
export const { setUserDatas } = ObjectSlice.actions;
export default ObjectSlice.reducer;
