import {
  Box,
  Button,
  createStyles,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { modals } from "@mantine/modals";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { login } from "../../store/slices/auth.slice";
import { Registration } from "./Registration";

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: "35px",
    textAlign: "center",
  },
  submitbttn: {
    align: "center",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 30px",
    gap: "10px",
    width: "auto",
    height: "41px",
    border: "1px solid #FF7400",
    borderRadius: "5px",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    backgroundColor: "#FF7400",
    lineHeight: "130%",
    color: "#F6F6F7",
    marginTop: "20px",
    marginBottom: "15px",
    ":hover": {
      backgroundColor: "#FAA83B",
      opacity: 0.8,
      border: "1px solid #FAA83B",
    },
    ":active": {
      boxShadow: "inset 0px 7px 10px rgba(72, 33, 0, 0.15)",
    },
  },
  text: {
    color: "#232528",
    fontSize: "16px",
    textAlign: "center",
    [`@media (max-width: ${theme.breakpoints.md})`]: {
      fontSize: "18px",
    },
  },
  enterbttn: {
    padding: 0,
    color: "#FF7400",
  },
  inputs: {
    borderColor: "#FAA83B",
    "&:focus": {
      borderColor: "#FAA83B",
    },
  },
}));

const InitialErrorMessages: any = { name: undefined, message: undefined };
const initialInputValues = { email: "", password: "" };

export function Login(): JSX.Element {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { authData } = useSelector((state: any) => state.authSlice);
  useEffect(() => {
    if (authData) {
      window.alert(authData.message);
    }
  }, [authData]);
  // React States
  const [inputValues, setInputValues] = useState(initialInputValues);
  // React States
  const [errorMessages, setErrorMessages] = useState(InitialErrorMessages);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    dispatch(login(inputValues));
  };
  const handleInputValues = (e: any) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  // Generate JSX code for error message
  const renderErrorMessage = (name: any) =>
    name === errorMessages.name && (
      <div className="error">{errorMessages.message}</div>
    );

  return (
    <Box>
      <Text className={classes.title}>Войти</Text>
      <Stack spacing="xs">
        <TextInput
          classNames={{ input: classes.inputs }}
          label="E-mail"
          onChange={handleInputValues}
          name="email"
          value={inputValues.email}
        />
        <TextInput
          classNames={{ input: classes.inputs }}
          label="Пароль"
          type="password"
          onChange={handleInputValues}
          name="password"
          value={inputValues.password}
        />
      </Stack>
      <Box sx={{ textAlign: "center" }}>
        <Button
        onClick={handleSubmit}
          onChange={handleInputValues}
          className={classes.submitbttn}
          type="submit"
        >
          Войти
        </Button>
      </Box>
      <Text className={classes.text}>
        Нет аккаунта?{" "}
            <Button 
            className={classes.enterbttn} 
            variant="white"
            
            >
            Зарегистрируйтесь
            </Button>
      </Text>
    </Box>
  );
}
