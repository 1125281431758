import { Box, Button, createStyles, Paper, Spoiler, Stack, Text } from '@mantine/core';
import { IconMapPin } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { convertPrice } from '../utils';

const useStyles = createStyles((theme) => ({
  primeBox: {
    maxWidth: 409,
    marginTop: '2rem',
  },
  paper: {
    transition: 'background-color 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: theme.colors.gray[2],
      cursor: 'pointer',
    },
  },
  image: {
    width: '100%',
    maxHeight: 236,
    background: 'url(image.png)',
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    flex: 'none',
    order: 0,
    flexGrow: 0,
  },
  title: {
    maxWidth: 321,
    fontSize: '25px',
    lineHeight: '120%',
    paddingBottom: '10px',
    paddingTop: '15px',
    [`@media (max-width: ${theme.breakpoints.md})`]: {
      fontSize: '20px',
    },
  },
  city: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    width: '149px',
    height: '21px',
    paddingBottom: '10px',
  },
  description: {
    fontSize: '16px',
    lineHeight: '130%',
    marginBottom: '10px',
  },
  price: {
    fontSize: '20px',
    lineHeight: '120%',
    color: '#FF7400',
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '10px 30px',
    flexDirection: 'row',
    width: 'auto',
    height: '44px',
    fontSize: '20px',
    fontWeight: 'normal',
    backgroundColor: '#FF7400',
    borderRadius: '5px',
    ':hover': {
      backgroundColor: '#FAA83B',
      opacity: 0.8,
      border: '1px solid #FAA83B',
      color: 'white',
    },
    ':active': {
      boxShadow: 'inset 0px 7px 10px rgba(72, 33, 0, 0.15)',
    },
  },
  spoiler: {
    color: '#FF7400',
    opacity: 0.8,
  },
}));

export function ObjectsCard({ object }: any): JSX.Element {
  const { classes } = useStyles();
  const converted = convertPrice(object?.price);
  return (
    <Box className={classes.primeBox}>
      <Paper key={object?.id} shadow="lg" p="md" withBorder className={classes.paper}>
        <img src={`${object?.images?.[0]}`} alt={object?.name} className={classes.image} />
        <Text className={classes.title}>{object?.name}</Text>
        <br />
        <Text className={classes.city}>
          <IconMapPin color="#FF7400" />
          {object?.type}
        </Text>
        <Spoiler
          maxHeight={40}
          showLabel="Показать больше"
          hideLabel="Скрыть"
          classNames={{ control: classes.spoiler }}
        >
          <Text className={classes.description}>{object?.description}</Text>
        </Spoiler>
        <Box>
          <Stack align="center">
            <Text className={classes.price}>{converted} ₽/час</Text>
            <Link style={{ textDecoration: 'none' }} to={`/objects/${object?.id}`}>
              <Button className={classes.button}>Забронировать</Button>
            </Link>
          </Stack>
        </Box>
      </Paper>
    </Box>
  );
}
