import { Box, Button, createStyles, Flex } from "@mantine/core";
import { modals } from '@mantine/modals';
import { Login } from "../Modal/Login";
import { Registration } from "../Modal/Registration";
import { LoginForm } from "./Forms/LoginForm";
import { RegisterForm } from "./Forms/RegisterForm";
import { useSelector } from "react-redux";
const useStyles = createStyles((theme) => ({
    box: {
        maxWidth: '300px',
    },
    buttonSignIn: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px 30px",
        gap: "10px",
        width: "auto",
        height: "44px",
        backgroundColor: "#FF7400",
        border: "1px solid #FF7400",
        borderRadius: "5px",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "20px",
        lineHeight: "120%",
        color: "#F6F6F7",
        ":hover": {
            backgroundColor: "#FAA83B",
            opacity: 0.8,
            border: "1px solid #FAA83B",
        },
        ":active": {
            boxShadow: 'inset 0px 7px 10px rgba(72, 33, 0, 0.15)',
        }
    },
    buttonLogin: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px 30px",
        gap: "10px",
        width: "auto",
        height: "44px",
        border: "1px solid #FF7400",
        borderRadius: "5px",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "20px",
        lineHeight: "120%",
        color: "#232528",
        ":hover": {
            backgroundColor: "#FAA83B",
            opacity: 0.8,
            border: "1px solid #FAA83B",
            color: 'white'
        },
        ":active": {
            boxShadow: 'inset 0px 7px 10px rgba(72, 33, 0, 0.15)',
        }
    },
}));

export function UserUnauth(): JSX.Element {
    const {isLogined} = useSelector((state:any)=>state.authSlice)
    const {classes} = useStyles();
    return(
        <Box className={classes.box}>
            <Flex justify='space-between' direction='row' >
                <Button   onClick={() =>
                modals.open({
                  title: <></>,
                  children:<Registration/>
                })
              } className={classes.buttonSignIn } style={{marginRight:"2em"}}>Регистрация</Button>
                <Button  onClick={() =>
                modals.open({
                  title: <></>,
                  children:<Login/>,
                })
              } className={classes.buttonLogin} variant="outline">Вход</Button>
            </Flex>
        </Box>
    )
}