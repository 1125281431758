import { Box, Button, createStyles, SimpleGrid, Paper, Select } from '@mantine/core';
import { Filters } from './Filters';
import { DateInput } from '@mantine/dates';
import { useState } from 'react';
import { IconCalendarPlus } from '@tabler/icons-react';
import { IconChevronDown } from '@tabler/icons-react';
import 'dayjs/locale/ru';
import { useDispatch } from 'react-redux';
import { setDate } from '../../store/slices/search.slice';
import { select_duration, select_time } from '../../mocks/selecters';

const useStyles = createStyles((theme) => ({
  button: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 30px',
    gap: '10px',
    width: 'auto',
    height: '48px',
    backgroundColor: '#FF7400',
    border: '1px solid #FF7400',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '120%',
    color: '#F6F6F7',
    borderRadius: '0px 5px 5px 0px',
    [`@media (max-width: ${theme.breakpoints.md})`]: {
      borderRadius: '5px',
    },
    ':hover': {
      backgroundColor: '#FAA83B',
      opacity: 0.8,
      border: '1px solid #FAA83B',
    },
    ':active': {
      boxShadow: 'inset 0px 7px 10px rgba(72, 33, 0, 0.15)',
    },
  },
  paperDate: {
    padding: '5px 6.5px',
    borderRadius: '5px 0px 0px 5px',
    border: '1px solid #FF7400',
    [`@media (max-width: ${theme.breakpoints.md})`]: {
      borderRadius: '5px',
    },
  },
  paperSelect: {
    padding: '5px 6.5px',
    borderRadius: '0px',
    border: '1px solid #FF7400',
    [`@media (max-width: ${theme.breakpoints.md})`]: {
      borderRadius: '5px',
    },
  },
  calendarInput: {
    '&[data-selected=true]': {
      backgroundColor: '#FAA83B',
      '&:hover': {
        backgroundColor: '#FF7400',
      },
    },
  },
  selectInput: {
    '&[data-selected=true]': {
      backgroundColor: '#FAA83B',
      '&:hover': {
        backgroundColor: '#FF7400',
      },
    },
  },
}));
const initialFilterValue = {
  date: new Date(),
  time: '',
  duration: '',
};

export function SearchPanel(): JSX.Element {
  const { classes } = useStyles();
  const [filterValues, setValue] = useState(initialFilterValue);
  const filterObjects = () => {
    const { time, date } = filterValues;
  };
  const dispatch = useDispatch();

  return (
    <Box>
      <SimpleGrid
        cols={5}
        spacing="0"
        breakpoints={[
          { maxWidth: 'xs', cols: 1, verticalSpacing: 'xs' },
          { maxWidth: 'sm', cols: 2, spacing: 'xs', verticalSpacing: 'xs' },
          { maxWidth: 'md', cols: 3, spacing: 'xs', verticalSpacing: 'xs' },
        ]}
      >
        <Box>
          <Paper className={classes.paperDate}  >
            <DateInput
              locale="ru"
              valueFormat="DD.MM.YYYY"
              value={filterValues.date}
              onChange={(val) => {
                if (val) {
                  setValue({ ...filterValues, date: val });
                  dispatch(setDate(val));
                }
              }}
              name="date"
              placeholder="Выберите дату"
              icon={<IconCalendarPlus size={20} />}
              variant="unstyled"
              classNames={{ day: classes.calendarInput }}
            />
          </Paper>
        </Box>
        <Box>
          <Paper className={classes.paperSelect} >
            <Select
              placeholder="Выберите время"
              data={select_time}
              name="time"
              value={filterValues.time}
              onChange={(val) => {
                setValue({ ...filterValues, time: val || '' });
              }}
              variant="unstyled"
              rightSection={<IconChevronDown size={16} />}
              classNames={{ item: classes.selectInput }}
            />
          </Paper>
        </Box>
        <Box>
          <Paper className={classes.paperSelect} >
            <Select
              placeholder="Выберите длительность"
              data={select_duration}
              name="duration"
              value={filterValues.duration}
              onChange={(val) => {
                setValue({ ...filterValues, duration: val || '' });
              }}
              variant="unstyled"
              rightSection={<IconChevronDown size={16} />}
              classNames={{ item: classes.selectInput }}
            />
          </Paper>
        </Box>
        <Filters />
        <Button className={classes.button} size="lg">
          Найти
        </Button>
      </SimpleGrid>
    </Box>
  );
}
