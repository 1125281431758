import { Box, Button, createStyles, Flex, Popover } from "@mantine/core";
import { IconAdjustmentsHorizontal } from "@tabler/icons-react";
import { useState } from "react";
import { IFilter } from "../../types";
import { Checkboxes } from "../Inputs/Checkboxes";
import { Prices } from "../Inputs/Price";

const useStyles = createStyles((theme) => ({
  popoverBox: {
    width: "589px",
    [`@media (max-width: ${theme.breakpoints.md})`]: {
      width: "353px",
    },
  },
  title: {
    fontWeight: 400,
    fontSixe: "20px",
    lineHight: "120%",
    color: "#C15B16",
    paddingBottom: "10px",
  },
  buttonFilter: {
    maxHeight: "48px",
    borderColor: "#FF7400",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 30px",
    gap: "10px",
    border: "1px solid #FF7400",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "120%",
    color: "#232528",
    [`@media (max-width: ${theme.breakpoints.md})`]: {
      borderRadius: "5px",
    },
    ":hover": {
      backgroundColor: "#FAA83B",
      opacity: 0.8,
      border: "1px solid #FAA83B",
      color: "white",
    },
    ":active": {
      boxShadow: "inset 0px 7px 10px rgba(72, 33, 0, 0.15)",
    },
  },
  buttonApply: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 30px",
    gap: "10px",
    width: "auto",
    height: "44px",
    backgroundColor: "#FF7400",
    border: "1px solid #FF7400",
    borderRadius: "5px",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "120%",
    color: "#F6F6F7",
    ":hover": {
      backgroundColor: "#FAA83B",
      opacity: 0.8,
      border: "1px solid #FAA83B",
    },
    ":active": {
      boxShadow: "inset 0px 7px 10px rgba(72, 33, 0, 0.15)",
    },
    [`@media (max-width: ${theme.breakpoints.md})`]: {
      fontSize: "18px",
      lineHeight: "130%",
      height: "43px",
    },
  },
  buttonReset: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 30px",
    gap: "10px",
    width: "auto",
    height: "44px",
    backgroundColor: "#232528",
    border: "1px solid #232528",
    borderRadius: "5px",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "120%",
    color: "#F6F6F7",
    ":hover": {
      backgroundColor: "#FAA83B",
      opacity: 0.8,
      border: "1px solid #FAA83B",
    },
    ":active": {
      boxShadow: "inset 0px 7px 10px rgba(72, 33, 0, 0.15)",
    },
    [`@media (max-width: ${theme.breakpoints.md})`]: {
      fontSize: "18px",
      lineHeight: "130%",
      height: "43px",
    },
  },
  boxInputs: {
    paddingBottom: "20px",
  },
  numberInputBorder: {
    borderColor: "#FAA83B",
    "&:focus": {
      borderColor: "#FAA83B",
    },
  },
  checkbox: {
    ":checked": {
      backgroundColor: "white",
      borderColor: "black",
    },
  },
}));

export function Filters(): JSX.Element {
  const { classes } = useStyles();
  const [popoverOpened, setPopoverOpened] = useState(false);

  const handleClosePopover = () => {
    setPopoverOpened(false);
  };
  return (
    <Box>
      <Popover opened={popoverOpened} onClose={handleClosePopover}>
        <Popover.Target>
          <Button
            leftIcon={<IconAdjustmentsHorizontal size={20} />}
            variant="outline"
            size="lg"
            radius="0"
            className={classes.buttonFilter}
            onClick={() => setPopoverOpened(!popoverOpened)}
          >
            Фильтры
          </Button>
        </Popover.Target>
        <Popover.Dropdown>
          <FiltersInput onClose={handleClosePopover} />
        </Popover.Dropdown>
      </Popover>
    </Box>
  );
}

function FiltersInput(props: IFilter): JSX.Element {
  const { classes } = useStyles();
  return (
    <Box className={classes.popoverBox}>
      <Box sx={{ marginBottom: "15px" }}>
        <Prices title={"Цена за час, ₽"} labels={["Да", "Нет"]} />
        <Checkboxes
          title={"Метро"}
          options={["Новослободская", "Сокольники", "Красные Ворота"]}
          columns={3}
        />
        <Checkboxes title={"Парковка"} options={["Да", "Нет"]} columns={3} />
        <Checkboxes
          title={"Оборудование"}
          options={[
            "Мокрая точка",
            "Вытяжка для кератина",
            "Лазерная эпиляция",
            "Учебный класс",
            "Кольцевая лампа",
            "Напольные коврики",
            "Лампа-лупа",
            "Барный стул",
            "Сухожар",
            "Мебель для индивидуальных консультаций",
          ]}
          columns={2}
        />
        <Checkboxes
          title={"Массажный стол"}
          options={["Любой", "С гидроприводомет", "Косметологическая кушетка"]}
          columns={2}
        />
        <Checkboxes
          title={"Звукоизоляция"}
          options={["Неважно", "Повышенная"]}
          columns={3}
        />
        <Checkboxes
          title={"Расходные материалы"}
          options={[
            "Без предоставления",
            "Возможность приобретения",
            "Входит в стоимость аренды",
          ]}
          columns={2}
        />
        <Checkboxes
          title={"Наличие душевой"}
          options={["Есть на территории центра", "Нет на территории центра"]}
          columns={2}
        />
        <Checkboxes
          title={"Режим работы"}
          options={["10-23", "Круглосуточно"]}
          columns={3}
        />
      </Box>
      <Flex justify="center" align="center" direction="row" gap={30}>
        <Button className={classes.buttonReset} onClick={() => props.onClose()}>
          Сбросить
        </Button>
        <Button className={classes.buttonApply}>Применить</Button>
      </Flex>
    </Box>
  );
}
