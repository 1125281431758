import { Box, Button, createStyles, Stack, Text, TextInput } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login, register } from '../../store/slices/auth.slice';

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: '35px',
    textAlign: 'center',
  },
  submitbttn: {
    align: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px 30px',
    gap: '10px',
    width: 'auto',
    height: '41px',
    border: '1px solid #FF7400',
    borderRadius: '5px',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    backgroundColor: '#FF7400',
    lineHeight: '130%',
    color: '#F6F6F7',
    marginTop: '20px',
    marginBottom: '15px',
    ':hover': {
      backgroundColor: '#FAA83B',
      opacity: 0.8,
      border: '1px solid #FAA83B',
    },
    ':active': {
      boxShadow: 'inset 0px 7px 10px rgba(72, 33, 0, 0.15)',
    },
  },

  text: {
    color: '#232528',
    fontSize: '16px',
    textAlign: 'center',
    [`@media (max-width: ${theme.breakpoints.md})`]: {
      fontSize: '18px',
    },
  },
  enterbttn: {
    padding: 0,
    color: '#FF7400',
  },
  inputs: {
    borderColor: '#FAA83B',
    '&:focus': {
      borderColor: '#FAA83B',
    },
  },
}));
const InitialErrorMessages: any = { name: undefined, message: undefined };
const initialInputValues = { name: '', surname: '', email: '', password: '', number: '' };

export function Registration(): JSX.Element {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const { authData } = useSelector((state: any) => state.authSlice);
  useEffect(() => {
    if (authData) {
      window.alert(authData.message);
    }
  }, [authData]);
  // React States
  const [inputValues, setInputValues] = useState(initialInputValues);
  const [errorMessages, setErrorMessages] = useState(InitialErrorMessages);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    // console.log(inputValues)
    dispatch(register(inputValues));
  };
  const handleInputValues = (e: any) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });
  };

  // Generate JSX code for error message
  const renderErrorMessage = (name: any) =>
    name === errorMessages.name && <div className="error">{errorMessages.message}</div>;

  return (
    <Box>
      <Text className={classes.title}>Регистрация</Text>
      <Stack spacing="xs">
        <TextInput
          classNames={{ input: classes.inputs }}
          label="Имя"
          onChange={handleInputValues}
          value={inputValues.name}
          type="text"
          name="name"
          required
        />
        <TextInput
          classNames={{ input: classes.inputs }}
          label="Фамилия"
          onChange={handleInputValues}
          value={inputValues.surname}
          type="text"
          name="surname"
          required
        />
        <TextInput
          classNames={{ input: classes.inputs }}
          label="Email"
          onChange={handleInputValues}
          value={inputValues.email}
          type="text"
          name="email"
          required
        />
        {/* <TextInput
          classNames={{ input: classes.inputs }}
          label="Number"
          onChange={handleInputValues}
          value={inputValues.number}
          type="text"
          name="number"
          required
        /> */}
        <TextInput
          classNames={{ input: classes.inputs }}
          label="Password"
          onChange={handleInputValues}
          value={inputValues.password}
          type="password"
          name="password"
          required
        />
      </Stack>
      <Box sx={{ textAlign: 'center' }}>
        <Button onClick={handleSubmit} onChange={handleInputValues} className={classes.submitbttn} type="submit">
          Зарегистрироваться
        </Button>
      </Box>
      <Text className={classes.text}>
        Уже есть аккаунт?{' '}
        <Button className={classes.enterbttn} variant="white">
          Войти
        </Button>
      </Text>
    </Box>
  );
}
