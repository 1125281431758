import { Link } from "react-router-dom";
import { UserUnauth } from "../Auth/UserUnAuth";
import { UserAuth } from "../Auth/UserAuth";


export const Header = (props:any): JSX.Element => {
  const {isLogined} = props
  return (
    <header style={{height:"5em"}}>
      <div
        style={{
          width: '100%',
          height: '2%',
          borderBottom: '2px solid rgb(235, 176, 99) ',
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-4">
             <Link to ={'/'}> <img src="https://beauty.norke.ru/wp-content/uploads/2023/02/Лого.png" /></Link>
            </div>
            <div className="col-md-4"></div>
            <div className="col-md-4">
               <div style={{float:"right",marginTop:"1em"}} className="auth">
               {isLogined?<UserAuth/>:<UserUnauth/>} 
               </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
