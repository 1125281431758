import { useRoutes, Routes, Route } from 'react-router-dom';
import { Objects } from './components/Object/Objects';
import { ObjectDetailsCard } from './components/Object/ObjectDetailsCard';
import { Profile } from './screens/Profile';
import { Details } from './screens/Detail';
import { Rates } from './screens/Rates';
import { Cheackouts } from './screens/Checkouts';
import { MyBookings } from './screens/myBookings';

export default function Router() {
  return (
    <Routes>
      <Route path="/" element={<Objects />}></Route>
      <Route path="objects/:workspaceId" element={<Details />}></Route>
      <Route path="objects/:workspaceId/rates" element={<Cheackouts />}></Route>
      <Route path="*" element={<h1>Not found</h1>}></Route>
      <Route path="/404" element={<h1>Not found 2</h1>}></Route>
      <Route path="/user/profile" element={<Profile />}></Route>
      <Route path="/user/bookings" element={<MyBookings />}></Route>
    </Routes>
  );
}
