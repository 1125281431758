import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import objectSlice from "./slices/object.slice";
import { searchSlice } from "./slices/search.slice";
import authSlice from "./slices/auth.slice";


export const store = configureStore({
  reducer: {
    objectSlice,
    authSlice,
    search: searchSlice.reducer,
  },
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
    // ...otherMiddlewares,
  ],
});
