import {
    Box,
    Button,
    createStyles,
    Popover,
    Table,
    Text,
    ScrollArea,
    Flex
} from "@mantine/core";
import { IconMapPin } from "@tabler/icons-react";
import { Section } from "../components/Partials/Section";
import { BookingTableProps } from "../types";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getUserBookings } from "../store/slices/auth.slice";
import moment from 'moment'
const useStyles = createStyles((theme) => ({
  tableData: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "120%",
    [`@media (max-width: ${theme.breakpoints.md})`]: {
      fontSize: "18px",
      lineHeight: "130%",
    },
  },
  tableHead: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "130%",
    color: "#232528",
    [`@media (max-width: ${theme.breakpoints.md})`]: {
      fontSize: "18px",
      lineHeight: "130%",
    },
  },
  buttonBox: {
    marginBottom: "40px",
    maxWidth: "266px",
  },
  buttonPrev: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 30px",
    maxWidth: "129px",
    gap: "10px",
    width: "auto",
    height: "41px",
    border: "1px solid #FF7400",
    borderRadius: "5px",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "130%",
    color: "#FF7400",
    ":hover": {
      backgroundColor: "#FAA83B",
      opacity: 0.8,
      border: "1px solid #FAA83B",
      color: "white",
    },
    ":active": {
      boxShadow: "inset 0px 7px 10px rgba(72, 33, 0, 0.15)",
    },
  },
  buttonNext: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 30px",
    gap: "10px",
    width: "auto",
    maxWidth: "129px",
    height: "41px",
    border: "1px solid #FF7400",
    borderRadius: "5px",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    backgroundColor: "#FF7400",
    lineHeight: "130%",
    color: "#F6F6F7",
    ":hover": {
      backgroundColor: "#FAA83B",
      opacity: 0.8,
      border: "1px solid #FAA83B",
    },
    ":active": {
      boxShadow: "inset 0px 7px 10px rgba(72, 33, 0, 0.15)",
    },
  },
  scroller: {
    "&, &:hover": {
      background:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
    '&[data-orientation="horizontal"] .mantine-ScrollArea-thumb': {
      backgroundColor: "#C15B16",
    },
    opacity: 1,
    background:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
  },
  popoverArrow: {
    border: "2px solid #FF7400",
    borderRadius: "5px",
  },
  paper: {
    padding: "10px",
    border: "2px solid",
    borderColor: "#FF7400",
    borderRadius: "5px",
  },
}));


export function MyBookings(): JSX.Element {
  const dispatch = useDispatch()
  const {userBookings,authData} = useSelector((state:any)=>state.authSlice)
  const { classes } = useStyles();
  useEffect(()=>{
    if(authData){
      const {accessToken} = authData
      dispatch(getUserBookings(accessToken))
    }
  },[authData])
  return (
    <Box>
      <Section title="Мои бронирования" />
      <Box className={classes.buttonBox}>
        <Flex justify="space-between" direction="row">
          <Button className={classes.buttonPrev} variant="outline">
            Прошлые
          </Button>
          <Button className={classes.buttonNext}>Будущие</Button>
        </Flex>
      </Box>

      <ScrollArea
        offsetScrollbars
        type="always"
        scrollbarSize={5}
        classNames={{ scrollbar: classes.scroller, corner: classes.scroller }}
      >
        <Box w={1300}>
          <BookingTable mybookings={userBookings} />
        </Box>
      </ScrollArea>
    </Box>
  );
}

// temp data

// Headers for table
const tableHeaders = ["Дата", "Время", "Локация", "Рабочее место", "Сумма", "Код"];
function BookingTable(props: any): JSX.Element {
  const { classes } = useStyles();
  return (
    <Box>
      <Table>
        <thead>
          <tr>
            {tableHeaders.map((header) => (
              <th key={header}>
                <Text className={classes.tableHead}>{header}</Text>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.mybookings.map((booking:any) => (
            <tr key={booking?.id}>
              <td>
                <Text className={classes.tableData}>{moment(booking?.checkIn).format('YYYY-MM-DD')}</Text>
              </td>
              <td>
                <Text className={classes.tableData}>{moment(booking?.checkIn).format('HH:mm')}</Text>
              </td>
              <td>
                <Text className={classes.tableData}>
                  <IconMapPin
                    color="#FF7400"
                    size={20}
                    style={{ marginRight: "8" }}
                  />
                  {booking?.cityName}
                </Text>
              </td>
              <td>
                <Text className={classes.tableData}>{booking?.name}</Text>
              </td>
              <td>
                <Text className={classes.tableData}>
                  {booking?.price.toLocaleString("ru-RU", {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: booking?.price >= 10000 ? 1 : 0,
                  })}{" "}
                  ₽
                </Text>
              </td>
              <td>
              <Text className={classes.tableData}>{booking?.passcode}</Text>
                
                
                {/* <Popover
                  position="bottom"
                  withArrow
                  zIndex={10}
                  shadow="md"
                  classNames={{
                    arrow: classes.popoverArrow,
                    dropdown: classes.popoverArrow,
                  }}
                >
                  <Popover.Target>
                    <Button
                      variant="white"
                      sx={{
                        textAlign: "center",
                        color: "#FF7400",
                        fontSize: "12px",
                      }}
                    >
                      Подробнее
                    </Button>
                  </Popover.Target>
                  <Popover.Dropdown>
                    <Box sx={{ width: "900px" }}>
                      {booking?.pinCode && (
                        <Box>
                          <Flex
                            justify="space-between"
                            direction="row"
                            align="center"
                          >
                            <Text>
                              Начало:{" "}
                              <span>
                                <b>{booking?.enterenceTime}</b>
                              </span>
                            </Text>
                            <Text>
                              Код открытия замка:{" "}
                              <span>
                                <b>{booking?.pinCode}</b>
                              </span>
                            </Text>
                            <Text>
                              Продолжительность:{" "}
                              <span>
                                <b>{booking?.duration} часа</b>
                              </span>
                            </Text>
                            <Box>
                              <Button
                                color="gray"
                                variant="outline"
                                // onClick={() => modals.open({
                                // size: "lg",
                                // title: <></>,
                                // children: <ModifyBooking/>
                                // })}
                              >
                                Изменить/перенести
                              </Button>
                            </Box>
                          </Flex>
                        </Box>
                      )}
                    </Box>
                  </Popover.Dropdown>
                </Popover> */}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Box>
  );
}
