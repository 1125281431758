import { ActionIcon, Box, Button, createStyles, Flex, SimpleGrid, Text } from '@mantine/core';
import { IconDoorExit } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import { UserNameProps } from '../../types';
import { useSelector } from 'react-redux';

const useStyles = createStyles((theme) => ({
  name: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '30px',
    lineHeight: '120%',
    color: '#232528',
  },
  userBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    maxWidth: '132px',
  },
}));

export function UserAuth(): JSX.Element {
  const { authData } = useSelector((state: any) => state.authSlice);
  const { classes } = useStyles();
  return (
    <Box className={classes.userBox}>
      <UserName email={authData?.user?.email} />
      <UserIcon image={authData?.user?.image} />
    </Box>
  );
}

//temp data

function UserName(props: any): JSX.Element {
  const { classes } = useStyles();
  return (
    <Box>
      <Text className={classes.name}>{props?.email}</Text>
    </Box>
  );
}
function UserIcon(props: any): JSX.Element {
  return (
    <Box>
      <Link to={`/user/profile`}>
        <ActionIcon variant="outline" radius="xl" size="xl" color="gray">
          {/* <img src={props?.image} alt="user icon"/> */}

          <img src="./icons/iconUser.svg" alt="user icon" />
        </ActionIcon>
      </Link>
    </Box>
  );
}
