import { InputBase, InputBaseProps } from '@mantine/core'
import 'react-phone-input-2/lib/plain.css'
import PhoneInput from 'react-phone-input-2'
import ru from 'react-phone-input-2/lang/ru.json'

interface CustomPhoneInputProps extends InputBaseProps {
    value: string
    onChange: (value: string) => void
}
export default function CustomPhoneInput(props: CustomPhoneInputProps): JSX.Element {
    return (
        <InputBase
            component={PhoneInput}
            country="ru"
            localization={ru}
            label="Номер телефона"
            withAsterisk
            inputProps={{
                copyNumbersOnly: false,
            }}
            inputStyle={{
                width: '100%',
                height: '90%',
                border: 'none',
                outline: 'none',
            }}
            styles={{
                input: {
                    '&:focus-within': {
                        borderColor: 'orange',
                    },
                },

            }}
            sx={theme => ({
                '.react-tel-input': {
                    fontFamily: theme.fontFamily,
                    fontSize: theme.fontSizes.md,
                },

                '.react-tel-input .flag-dropdown': {
                    border: 'none',
                },

                '.react-tel-input .form-control:focus': {
                    border: 'none',
                    boxShadow: 'none',
                },

                '.react-tel-input .flag-dropdown.open': {
                    background: 'transparent',
                    border: 'none',
                },

                '.react-tel-input .flag-dropdown.open .selected-flag': {
                    background: 'transparent',
                    border: 'none',
                },

                '.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus, .react-tel-input .selected-flag.open':
                    {
                        background: 'transparent',
                        border: 'none',
                    },

                '.react-tel-input .selected-flag .arrow': {
                    borderTopColor:
                        theme.colorScheme === 'dark'
                            ? theme.colors.dark[0]
                            : theme.colors.gray[7],
                },

                '.react-tel-input .selected-flag .arrow.up': {
                    borderBottomColor:
                        theme.colorScheme === 'dark'
                            ? theme.colors.dark[0]
                            : theme.colors.gray[7],
                },

                '.react-tel-input .country-list': {
                    border: 'none',
                    background:
                        theme.colorScheme === 'dark'
                            ? theme.colors.dark[6]
                            : 'white',
                },

                '.react-tel-input .country-list .country.highlight': {
                    color: 'white',
                    background: '#FAA83B',
                    border: 'none',
                    '&:hover': {
                        background: '#FF7400',
                    }
                },

                '.react-tel-input .country-list .country': {
                    borderRadius: '5px',
                    border: 'none',
                }, 

                label: {
                    fontWeight: 400
                },   
            })}
            {...props}
        />
    )
}
