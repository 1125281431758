import { useEffect } from 'react';
import { Box, Grid } from '@mantine/core';
import { ObjectsCard } from './ObjectsCard';
import { useDispatch, useSelector } from 'react-redux';
import { getObjects, getWorkspaces } from '../../store/slices/object.slice';
import { YandexMap } from '../Map/Map';

const organization_id = 1;

export function Objects(): JSX.Element {
  const dispatch = useDispatch();
  const { objects } = useSelector((state: any) => state.objectSlice);
  const { workspaces } = useSelector((state: any) => state.objectSlice);

  const placemarks = objects?.rows?.map((obj: any) => ({
    id: obj.id,
    name: obj.name,
    coordinates: [obj.latitude, obj.longtitude],
  }));

  useEffect(() => {
    dispatch(getWorkspaces({ organization_id }));
    dispatch(getObjects({ organization_id }));
  }, []);

  return (
    <Box>
      <Grid sx={{ justifyContent: 'center' }}>
        <Grid.Col span="content" md={'auto'}>
          <Box>
            {workspaces?.rows?.map((object: any, index: number) => (
              <ObjectsCard object={object} key={index} />
            ))}
          </Box>
        </Grid.Col>
        <Grid.Col span="content">
          <YandexMap placemarks={placemarks} />
        </Grid.Col>
      </Grid>
    </Box>
  );
}
