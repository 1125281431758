import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import { API } from '../../api';
import { stat } from 'fs';


export const register: any = createAsyncThunk(
  'auth/register',
  async (userDatas: any, { rejectWithValue }): Promise<any> => {
    try {
      const response = await API.register(userDatas);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response);
    }
  }
);


export const login: any = createAsyncThunk(
  'auth/login',
  async (userDatas: any, { rejectWithValue }): Promise<any> => {
    try {
      const response = await API.login(userDatas);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response);
    }
  }
);

export const getProfile: any = createAsyncThunk(
  'auth/getProfile',
  async (userDatas: any, { rejectWithValue }): Promise<any> => {
    try {
      const response = await API.getProfile(userDatas);
      return response;
    } catch (err: any) {
      console.log(err)
      return rejectWithValue(err.response);
    }
  }
);

export const getUserBookings: any = createAsyncThunk(
  'auth/getUserBookings',
  async (accessToken: string, { rejectWithValue }): Promise<any> => {
    try {
      const response = await API.getUserBookings(accessToken);
      return response;
    } catch (err: any) {
      console.log(err)
      return rejectWithValue(err.response);
    }
  }
);


const AuthSlice = createSlice({
  name: 'auth',
  initialState: {
    isLogined:false,
    authData:null,
    userData:null,
    userBookings:[]
  },
  reducers: {
    setLoginState :(state,{payload})=>{
      state.isLogined = true
      const parsed = JSON.parse(payload)
      const {data} = parsed
      state.authData=data
    },
    setLogoutState :(state,{payload})=>{
      state.isLogined = false
      state.authData=null
    }
  },
  extraReducers: {

    [register.pending.type]: (state, action) => {},
    [register.rejected.type]: (state, {payload}) => {
      const {data}  = payload
      state.authData=data
    },
    [register.fulfilled.type]: (state, { payload }) => {
      // state.objects = payload.data;
      const {data}  = payload
      state.isLogined=true
      state.authData=data
    },


    
    [login.pending.type]: (state, action) => {},
    [login.rejected.type]: (state, {payload}) => {
      const {data}  = payload
      state.authData=data
    },
    [login.fulfilled.type]: (state, { payload }) => {
      const {data}  = payload
      state.authData=data
      localStorage.setItem('authData',JSON.stringify(data))
    },


    [getProfile.pending.type]: (state, action) => {
      // console.log('pending')
    },
    [getProfile.rejected.type]: (state, action) => {
      // console.log(action)
      // const {data}  = payload
    },
    [getProfile.fulfilled.type]: (state, { payload }) => {
      state.userData = payload?.data
     
    },




    [getUserBookings.pending.type]: (state, action) => {
      // console.log('pending')
    },
    [getUserBookings.rejected.type]: (state, action) => {
      // console.log(action)
      // const {data}  = payload
    },
    [getUserBookings.fulfilled.type]: (state, { payload }) => {
      state.userBookings = payload?.data
    },
  },

  
});

// export const userDatas = (state: any) => state.object.userDatas;
export const { setLoginState,setLogoutState } = AuthSlice.actions;
export default AuthSlice.reducer;
