import { Box, createStyles, Paper } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { IconCalendarPlus } from '@tabler/icons-react';
import 'dayjs/locale/ru';
import { useDispatch, useSelector } from 'react-redux';
import { selectDate, setDate } from '../../store/slices/search.slice';

const useStyles = createStyles((theme) => ({
  paper: {
    padding: '5px 6.5px',
    borderRadius: '5px 0px 0px 5px',
    [`@media (max-width: ${theme.breakpoints.md})`]: {
      borderRadius: '5px',
    },
  },
  calendarInput: {
    '&[data-selected=true]': {
      backgroundColor: '#FAA83B',
      '&:hover': {
        backgroundColor: '#FF7400',
      },
    },
  },
}));

export function EnterenceDate(): JSX.Element {
  const { classes } = useStyles();
  const date = useSelector(selectDate);
  const dispatch = useDispatch();

  return (
    <Box>
      <Paper className={classes.paper} withBorder>
        <DateInput
          locale="ru"
          valueFormat="DD.MM.YYYY"
          value={date}
          placeholder="Выберите дату"
          icon={<IconCalendarPlus size={20} />}
          variant="unstyled"
          classNames={{ day: classes.calendarInput }}
          onChange={(value) => {
            if (value) {
              dispatch(setDate(value));
            }
          }}
        />
      </Paper>
    </Box>
  );
}
