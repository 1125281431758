import { createSlice } from '@reduxjs/toolkit';

interface SearchState {
  date: Date;
  time: string;
  duration: string;
}
const initialState: SearchState = {
  date: new Date(),
  time: '',
  duration: '',
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setDate: (state, action) => {
        state.date = action.payload;
    },
    setTime: (state, action) => {
        state.time = action.payload;
    },
    setDuration: (state, action) => {
        state.duration = action.payload;
    },
  },
});
export const selectDate = (state: any) => state.search.date;
export const selectTime = (state: any) => state.search.time;
export const selectDuration = (state: any) => state.search.duration;

export const { setDate, setDuration, setTime } = searchSlice.actions;

